
import { useEffect, useRef, useState } from "react";
import pippip from "../../../asset/pippip.png";
import senbay from "../../../asset/senbay.png";
import { TbGridDots } from "react-icons/tb";



export function BusinessBox() {
  const ref = useRef<HTMLDivElement>(null);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowInfo(false);
        // console.log("hi")
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);



  return (
    <>
      <div ref={ref}>
        <TbGridDots
          className="service-icon"
          onClick={() => {
            setShowInfo((prevState) => !prevState);
          }}
        />
      </div>
      {showInfo && (
        <div className="business-list" 
          style={{ 
            height: "fit-content",
            padding: 0,
            width: '220px'
          }}
        >
          <a className="business-item" href="https://pippip.vn/appdownload" target="_blank">
            <div className="business-image">
              <img src={pippip} alt="pip" />
            </div>
            <div className="business-title">Đặt xe ô tô</div>
          </a>
          <a className="business-item" href="https://senbay.vn" target="_blank">
            <div className="business-image">
              <img src={senbay} alt="pip" />
            </div>
            <div className="business-title">Vé máy bay</div>
          </a>
        </div>
      )}
    </>
  );
}
