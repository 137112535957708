import React, { useContext, useEffect, useState } from "react";
import { Button, Row } from "antd";
import "./_link_services.scss";
import { useLocation } from "react-router-dom";

import pippip from "../../asset/pippip.png";
import senbay from "../../asset/senbay.png";


const LinkServices = () => {

  const location = useLocation();

  if (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/register") {
    return null;
  }

  return (
    <>
      <div className="business-container">
        <div className="business-list">
            <a className="business-item" href="https://senbay.vn" target="_blank">
              <div className="business-image">
                <img src={senbay} alt="pip" />
              </div>
              <div className="business-title">Vé máy bay</div>
            </a>
            <a className="business-item" href="https://pippip.vn/appdownload" target="_blank">
              <div className="business-image">
                <img src={pippip} alt="pip" />
              </div>
              <div className="business-title">Đặt xe ô tô</div>
            </a>
        </div>
      </div>
    </>
  );
}

export default LinkServices;
