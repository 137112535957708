import { createContext, useEffect, useState } from "react";


import { DocumentData } from "../component/chat/Message";
interface Props {
  children: React.ReactNode;
}
export type AuthContextType = {
  currentUser: DocumentData;
  setCurrentUser: React.Dispatch<React.SetStateAction<DocumentData>>;
};
export const AuthContext = createContext<AuthContextType | null>(null);
// dung khi can luu state user
export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<DocumentData>(Object);

  useEffect(() => {
    if (currentUser?.uid) {
      sessionStorage.setItem(`${currentUser.uid}`, JSON.stringify(currentUser));
    }
  }, [currentUser]);
  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
