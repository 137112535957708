import { useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router";
import {
  INITIAL_STATE,
  SearchContext,
  SearchContextType,
} from "../../context/SearchContext";
import { FilterContext } from "../../context/FilterContext";
import { ModalContext } from "../../context/ModalContext";
import { getProvinceList, getDistrictList } from "../../services/search";
import { CountContext } from "../../context/CountContext";
import { Search } from "../../context/SearchContext";
async function fetchProvince() {
  try {
    const res = await getProvinceList();
    const provinces = res.provinces.map((provinceName: string) => ({
      value: provinceName,
      label: provinceName,
    }));
    return provinces;
  } catch (e) {
    console.log(e);
    return [];
  }
}

async function fetchDistrict(provinceName: string | null) {
  try {
    const res = await getDistrictList(provinceName);
    const districts = [
      {
        value: "Tất cả",
        label: "Tất cả",
      },
    ];
    districts.push();
    res.districts.forEach((districtName: string) =>
      districts.push({
        value: districtName,
        label: districtName,
      })
    );

    return districts;
  } catch (e) {
    console.log(e);
    return [];
  }
}

interface LabelType {
  label: string;
}

async function fetchInitialData() {
  // const { cityId, districtId } = (await axios.get(PATHS.LOCATION)).data;
  const [cities, districts] = await Promise.all([
    fetchProvince(),
    fetchDistrict("Tỉnh Hòa Bình"),
  ]);

  return {
    cityOptions: cities,
    districtOptions: districts,
    numberPeople: peopleOptions,
    selectedCity: cities.find(
      ({ label }: LabelType) => label === "Tỉnh Hòa Bình"
    ),
    selectedDistrict: districts.find(
      ({ label }) => label === "Thành phố Hòa Bình"
    ),
    selectedPeople: peopleOptions.find(({ label }) => label === "1-5 người"),
    // selectedDistrict: districts.find(({ label }) => label === "Kỳ Sơn"),
  };
}

async function fetchInitData(searchState: Search) {
  // const { cityId, districtId } = (await axios.get(PATHS.LOCATION)).data;
  const [cities, districts] = await Promise.all([
    fetchProvince(),
    fetchDistrict(searchState.provinceName),
  ]);

  return {
    cityOptions: cities,
    districtOptions: districts,
    numberPeople: peopleOptions,
    selectedCity: cities.find(
      ({ label }: LabelType) => label === searchState.provinceName
    ),
    selectedDistrict: districts.find(
      ({ label }) => label === searchState.districtName
    ),
    selectedPeople: peopleOptions.find(
      ({ label }) => label === searchState.capacity
    ),
  };
}

export interface OptionType {
  value: string;
  label: string;
}

export interface CapacityOptionType extends OptionType {
  minCapacity: number;
  maxCapacity: number;
}

export interface SearchDataType {
  cityOptions: OptionType[];
  districtOptions: OptionType[];
  numberPeople: CapacityOptionType[];
  selectedCity: OptionType | null | undefined;
  selectedDistrict: OptionType | null | undefined;
  selectedPeople: CapacityOptionType | null | undefined;
}
function useLocationForm(shouldFetchInitialLocation: boolean) {
  const isMatchPath = useMatch("/area");
  const navigate = useNavigate();
  const { dispatch } = useContext(SearchContext) as SearchContextType;
  const { dispatchFilter } = useContext(FilterContext);
  const { dispatchCount } = useContext(CountContext);
  const [state, setState] = useState<SearchDataType>({
    cityOptions: [],
    districtOptions: [],
    selectedCity: null,
    selectedDistrict: null,
    selectedPeople: null,
    numberPeople: [],
  });
  const { selectedCity } = state;

  useEffect(() => {
    const filter = localStorage.getItem("filter");
    const search = localStorage.getItem("search");
    const favor = localStorage.getItem("favorite");

    let filterStatus = null,
      searchStatus = null,
      favorites = null;
    if (filter) {
      filterStatus = JSON.parse(filter);
    }
    if (search) {
      searchStatus = JSON.parse(search);
    }

    if (favor) {
      favorites = JSON.parse(favor);
      dispatchCount({
        type: "NEW_COUNT",
        payload: { count: favorites.length },
      });
    }

    (async function () {
      // neu co searchStatE
      if (!searchStatus) {
        const initialData = await fetchInitialData();
        setState(initialData);
      } else {
        //todo: xu ly search state null - loop through tao null
        dispatchFilter({
          type: "NEW_FILTER",
          payload: filterStatus
            ? filterStatus
            : { price: null, priceType: null },
        });
        dispatch({
          type: "NEW_SEARCH",
          payload: searchStatus,
        });

        const initialData = await fetchInitData(searchStatus);
        setState(initialData);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (!selectedCity) return;
      const options = await fetchDistrict(selectedCity.value);
      setState((prevState) => {
        return { ...prevState, districtOptions: options };
      });
    })();
  }, [selectedCity]);

  function onCitySelect(option: OptionType) {
    if (option !== selectedCity) {
      setState({
        ...state,
        districtOptions: [],
        selectedCity: option,
        selectedDistrict: null,
      });
    }
  }

  function onPeopleSelect(option: CapacityOptionType) {
    setState({
      ...state,
      selectedPeople: option,
    });
  }

  function onDistrictSelect(option: OptionType) {
    setState({
      ...state,
      selectedDistrict: option,
    });
  }

  const { modalDispatch } = useContext(ModalContext);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let searchState: Search = INITIAL_STATE;
    if (state.selectedCity) {
      searchState.provinceName = state.selectedCity.label;
    }
    if (state.selectedDistrict) {
      searchState.districtName = state.selectedDistrict.label;
    }
    if (state.selectedPeople) {
      searchState.areaOccupancy = state.selectedPeople.value; // 5
      searchState.minCapacity = state.selectedPeople.minCapacity;
      searchState.maxCapacity = state.selectedPeople.maxCapacity;
      searchState.capacity = state.selectedPeople.label; // 1-5 nguoi
    }

    if (!isMatchPath) {
      navigate("/area");
    }

    dispatch({ type: "NEW_SEARCH", payload: {...searchState} });
    modalDispatch({ type: "closeModal" });
  }

  return {
    state,
    onCitySelect,
    onDistrictSelect,
    onPeopleSelect,
    handleSubmit,
  };
}

export default useLocationForm;
const peopleOptions = [
  { value: "5", label: "1-5 người", minCapacity: 1, maxCapacity: 5 },
  { value: "25", label: "5-25 người", minCapacity: 5, maxCapacity: 25 },
  { value: "50", label: "25-50 người", minCapacity: 25, maxCapacity: 50 },
  { value: "100", label: "50-100 người", minCapacity: 50, maxCapacity: 100 },
  { value: "101", label: "Trên 100 người", minCapacity: 100, maxCapacity: 100 },
];
