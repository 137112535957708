import React from "react";
import useLocationForm, { CapacityOptionType } from "./useLocationForm";
import { Row, Col } from "antd";
import Select from "react-select";
import { FaCity } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoPeopleOutline } from "react-icons/io5";
import { BsSearch } from "react-icons/bs";
import "./_search.scss";

const style = {
  control: (base: any) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};
interface SearchFormType {
  isInHomePage?: boolean;
  className: string;
}
const SearchForm = ({ isInHomePage, className }: SearchFormType) => {
  const {
    state,
    onCitySelect,
    onDistrictSelect,
    onPeopleSelect,
    handleSubmit,
  } = useLocationForm(true);

  const {
    cityOptions,
    districtOptions,
    selectedCity,
    selectedDistrict,
    numberPeople,
    selectedPeople,
  } = state;

  if (isInHomePage) {
    return <></>;
  }

  return (
    <form className={`search-form ${className}`} onSubmit={handleSubmit}>
      <Col md={7} className="hero-container">
        <FaCity className="icon" />
        <Select
          className="select"
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={style}
          name="cityId"
          key={`cityId_${selectedCity?.value}`}
          isDisabled={cityOptions.length === 0}
          options={cityOptions}
          onChange={(option) => {
            if (option === null) return;
            onCitySelect(option);
          }}
          placeholder="Tỉnh/Thành"
          defaultValue={selectedCity}
        />
      </Col>
      <div className="vertical"></div>
      <Col md={8} className="hero-container">
        <HiOutlineLocationMarker className="icon" />
        <Select
          className="select"
          styles={style}
          components={{
            IndicatorSeparator: () => null,
          }}
          name="districtId"
          key={`districtId_${selectedDistrict?.value}`}
          isDisabled={districtOptions.length === 0}
          options={districtOptions}
          onChange={(option) => {
            if (option === null) return;
            onDistrictSelect(option);
          }}
          placeholder="Quận/Huyện"
          defaultValue={selectedDistrict}
        />
      </Col>
      <div className="vertical"></div>
      <Col md={6} className="hero-container">
        <IoPeopleOutline className="icon" />
        <Select
          name="number"
          className="select"
          key={`number_${selectedPeople?.value}`}
          styles={style}
          components={{
            IndicatorSeparator: () => null,
          }}
          options={numberPeople}
          onChange={(option) => {
            if (option === null) return;
            onPeopleSelect(option);
          }}
          defaultValue={selectedPeople}
          placeholder="Số lượng"
        />
      </Col>
      <Col md={2} className="submit">
        <button type="submit" className="but-submit" >
          <BsSearch style={{ marginBottom: "8px"}}/>
        </button>
      </Col>
    </form>
  );
};

export default SearchForm;
