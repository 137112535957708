import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  isVisibleOnMobile: false,
};
interface Modal {
  isVisibleOnMobile: boolean;
}
interface ModalActionType {
  type: "openModal" | "closeModal";
  payload?: Modal;
}

export type ModalContextType = {
  modalCtx: Modal;
  modalDispatch: React.Dispatch<ModalActionType>;
};
export const ModalContext = createContext<ModalContextType>({
  modalCtx: INITIAL_STATE,
  modalDispatch: () => {},
});

const ModalReducer = (state: Modal, action: ModalActionType) => {
  switch (action.type) {
    case "openModal": {
      return {
        ...state,
        isVisibleOnMobile: true,
      };
    }
    case "closeModal": {
      return {
        ...state,
        isVisibleOnMobile: false,
      };
    }

    default: {
      return state;
    }
  }
};

export const ModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [modalCtx, modalDispatch] = useReducer(ModalReducer, INITIAL_STATE);

  return (
    <ModalContext.Provider value={{ modalCtx, modalDispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
