import React from "react";
import { Route, Routes } from "react-router-dom";

const Login = React.lazy(() => import("../view/auth/Login"));
const Register = React.lazy(() => import("../view/auth/Register"));

function AuthRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
    </Routes>
  );
}

export default AuthRoutes;
