import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/AuthContext";
import { SearchContextProvider } from "./context/SearchContext";
import { ModalContextProvider } from "./context/ModalContext";
import { FilterContextProvider } from "./context/FilterContext";
import { CountContextProvider } from "./context/CountContext";

ReactDOM.render(
  <SearchContextProvider>
    <CountContextProvider>
      <ModalContextProvider>
        <AuthContextProvider>
          <FilterContextProvider>
            <App />
          </FilterContextProvider>
        </AuthContextProvider>
      </ModalContextProvider>
    </CountContextProvider>
  </SearchContextProvider>,
  document.getElementById("root")
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
