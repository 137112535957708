import { FolderOutlined, BookOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { Badge } from "antd";
import useFavoriteCount from "./useFavoriteCount";
import "./_favoriteHeader.scss";

function FavoriteHeader() {
  const navigate = useNavigate();
  const filterCount = useFavoriteCount();
  return (
    <div className="service">
      <Badge count={filterCount} size={"small"} className="filter-count">
        <div className="notification-icon" onClick={() => navigate("/favorite")}>
          <FolderOutlined className="icon" style={{ fontSize: "26px", fontWeight: "500", width: '40px' }} />
        </div>
      </Badge>
    </div>
  );
}

export default FavoriteHeader;
