import React, { useState, useEffect, useContext } from "react";
import { CountContext, CountContextType } from "../../../context/CountContext";
function useFavoriteCount() {
  const [filterCount, setCount] = useState(0);
  const { countState } = useContext(CountContext) as CountContextType;

  useEffect(() => {
    setCount(countState.count);
  }, [countState.count]);

  return filterCount;
}

export default useFavoriteCount;
