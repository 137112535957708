import React from "react";
import { Route, Routes } from "react-router-dom";


const Loading = React.lazy(() => import("../view/chat/Loading"));
function ChatRoutes() {
  return (
    <Routes>
      <Route path="/sale-info" element={<Loading/>} />
    </Routes>
  );
}

export default ChatRoutes;
