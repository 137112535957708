import React, { createContext, useReducer } from "react";

export interface Search {
  provinceName: string|null;
  districtName: string|null;
  areaOccupancy: string|null;
  minCapacity: number|null;
  maxCapacity: number|null;
  capacity: string|null;
}
interface SearchActionType {
  type: "NEW_SEARCH" | "RESET_SEARCH";
  payload: Search;
}

export type SearchContextType = {
  searchState: Search;
  dispatch: React.Dispatch<SearchActionType>;
};
export const INITIAL_STATE = {
  provinceName: null,
  districtName: null,
  areaOccupancy: null,
  minCapacity: null,
  maxCapacity: null,
  capacity: null,
};



// case remove filter
const SearchReducer = (state: Search, action: SearchActionType): Search => {
  switch (action.type) {
    case "NEW_SEARCH":
      localStorage.setItem("search", JSON.stringify(action.payload));
      return action.payload;
    case "RESET_SEARCH":
      return INITIAL_STATE;
    default:
      return state;
  }
};

interface Props {
  children: React.ReactNode;
};

export const SearchContext = createContext<SearchContextType | null>(null);
export const SearchContextProvider:React.FC<Props> = ({ children }) => {
  const [searchState, dispatch] = useReducer(SearchReducer, INITIAL_STATE);

  return (
    <SearchContext.Provider value={{searchState,dispatch}}>
      {children}
    </SearchContext.Provider>
  );
};

