import React, { Fragment } from "react";

import classes from "./Modalv2.module.scss";
import ReactDOM from "react-dom";

const Backdrop = ({ onClose }: { onClose?: () => void }) => {
  return <div className={classes.backdrop} onClick={onClose} />;
};

interface ModalPropsType {
  children?: React.ReactNode;
  onClose?: () => void;
  classModal?: string;
}
const ModalOverlay = ({ children, onClose, classModal }: ModalPropsType) => {
  return (
    <div className={`${classes.modal} ${classModal}`}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

function Modalv2({ children, onClose, classModal }: ModalPropsType) {
  const overlay = document.getElementById("overlays");
  if (overlay === null) return <></>;
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClose={onClose} />, doSomething(overlay))}
      {ReactDOM.createPortal(
        <ModalOverlay onClose={onClose} classModal={classModal}>
          {children}
        </ModalOverlay>,
        doSomething(overlay)
      )}
    </Fragment>
  );
}

export default Modalv2;

function doSomething(element: Element | DocumentFragment) {
  // do something with element
  return element
}