import React from "react";
import { Route, Routes } from "react-router-dom";

const HomePage = React.lazy(() => import("../view/page/HomePage"));
const OrderPage = React.lazy(() => import("../view/page/OrderPage"));
const FeedbackPage = React.lazy(() => import("../view/page/FeedbackPage"));
const DetailOrder = React.lazy(() => import("../view/page/DetailOrder"));

function HomePageRoutes() {
  return (
    <Routes>
      <Route path="/home" element={<HomePage />} />
      <Route path="/home/list-order" element={<OrderPage />} />
      <Route path="/home/feedback" element={<FeedbackPage />} />
      <Route path="/home/list-order/:orderId" element={<DetailOrder />} />
    </Routes>
  );
}

export default HomePageRoutes;
