import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  count: 0,
};
interface CountType {
  count: number;
}
interface CountActionType {
  type: "NEW_COUNT" | "RESET_COUNT" | "INCREMENT_COUNT" | "DECREMENT_COUNT";
  payload?: CountType;
}

export type CountContextType = {
  countState: CountType;
  dispatchCount: React.Dispatch<CountActionType>;
};
export const CountContext = createContext<CountContextType>({
  countState: INITIAL_STATE,
  dispatchCount: () => {},
});

// case remove filter
const CountReducer = (state: CountType, action: CountActionType) => {
  switch (action.type) {
    case "NEW_COUNT":
      if (action.payload === undefined) return INITIAL_STATE;
      return action.payload;
    case "INCREMENT_COUNT":
      return { ...state, count: state.count + 1 };
    case "DECREMENT_COUNT":
      return { ...state, count: state.count - 1 };
    case "RESET_COUNT":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const CountContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [countState, dispatchCount] = useReducer(CountReducer, INITIAL_STATE);
  // value obj countState,dispatchCount
  return (
    <CountContext.Provider
      value={{
        countState,
        dispatchCount,
      }}
    >
      {children}
    </CountContext.Provider>
  );
};
