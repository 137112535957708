import { AxiosConfig, AxiosConfigLogin } from "../config/AxiosConfig";

const axios = AxiosConfig();
const axiosLogin = AxiosConfigLogin();

export const login = async (data: object) => {
  let api = `/auth-customer/login`;
  try {
    const res = await axios.post(api, data);
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const register = async (data: object) => {
  let api = `/auth-customer/register`;
  try {
    const res = await axios.post(api, data);
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const getDataUser = async () => {
  let api = `/auth-customer/profile`;
  try {
    const res = await axiosLogin.get(api);
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const changeAvatar = async (id: string, data: object) => {
  let api = `/auth-customer/change-avatar/${id}`;
  console.log("check data: ", data);
  try {
    const res = await axiosLogin.put(api, data, {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    });
    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
};
