import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  priceType: null,
  price: null,
};
export interface Filter {
  [key: string]: string | null | number | undefined;
}
interface FilterActionType {
  type: "NEW_FILTER" | "RESET_FILTER";
  payload: Filter;
}

export type FilterContextType = {
  filterState: Filter;
  dispatchFilter: React.Dispatch<FilterActionType>;
};
export const FilterContext = createContext<FilterContextType>({
  filterState: INITIAL_STATE,
  dispatchFilter: () => {},
});

// case remove filter
const FilterReducer = (state: Filter, action: FilterActionType) => {
  switch (action.type) {
    case "NEW_FILTER":
      localStorage.setItem("filter", JSON.stringify(action.payload));
      return action.payload;
    case "RESET_FILTER":
      localStorage.setItem("filter", JSON.stringify(INITIAL_STATE));
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const FilterContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [filterState, dispatchFilter] = useReducer(
    FilterReducer,
    INITIAL_STATE
  );

  return (
    <FilterContext.Provider
      value={{
        filterState: filterState,
        dispatchFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
