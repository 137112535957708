import React from "react";
import { Route, Routes } from "react-router-dom";

const Profile = React.lazy(() => import("../view/page/Profile"));

function ProfileRoutes() {
  return (
    <Routes>
      <Route path="/profile" element={<Profile />} />
    </Routes>
  );
}

export default ProfileRoutes;
