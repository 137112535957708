import React from "react";
import { Route, Routes } from "react-router-dom";

const Favorite = React.lazy(() => import("../view/favorite/Favorite"));

function FavoriteRoutes() {
  return (
    <Routes>
      <Route path="/favorite" element={<Favorite />} />
    </Routes>
  );
}

export default FavoriteRoutes;
